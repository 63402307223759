import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "../css/markdown-github.css";
import ReactDisqusComments from 'react-disqus-comments';

import {
    FaTwitter, FaFacebook, FaEnvelope, FaLinkedin
} from 'react-icons/fa';
import { ShareButtonRectangle, ShareBlockStandard } from "react-custom-share";

export default ({ data }) => {
  const { post } = data;
  const title = post.frontmatter.title;
  const slug = post.frontmatter.slug;
  const siteUrl = data.site.siteMetadata.siteUrl;
  const siteTitle = data.site.siteMetadata.title;
  const url = post.frontmatter.path;
  const shareBlockProps = {
    url: siteUrl+url,
    button: ShareButtonRectangle,
    buttons: [
      { network: "Twitter", icon: FaTwitter },
      { network: "Facebook", icon: FaFacebook },
      { network: "Email", icon: FaEnvelope },
      { network: "Linkedin", icon: FaLinkedin }
    ],
    text: `Un article du site psytroyes.fr : ` + title,
    longtext: `Jette un oeil à ce superbe article que je viens de trouver.`
  };
  console.log(post.frontmatter.dateModified);
  return (
    <Layout headerClass="relative bg-white" bodyClass="px-0 md:px-0 lg:px-0">
      <SEO
        title={title}
        description={post.excerpt}
        dateModified={post.frontmatter.dateModified}
        datePublished={post.frontmatter.datePublished}
        isBlogPost={true}
        postImage={post.image}
        slug={slug}
      />

      <div className="container mx-auto px-6 md:px-10 lg:px-24 pt-16 ">
        <h2 className="text-5xl text-lblue">
          {title}
        </h2>
        <div className="markdown-body">
          <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
        </div>
        <div className="p-2">
        <ShareBlockStandard {...shareBlockProps} />
        </div>

        <ReactDisqusComments
          shortname="clhpsy"
          identifier={slug}
          title={title}
          url={url}
          />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($id: String) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    post: markdownRemark(id: { eq: $id }) {
      frontmatter {
        path
        image
        title
        slug
        dateModified
        datePublished
      }
      excerpt
      html
    }
  }
`;
